import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import Text from 'theme/text';
import translations from 'translations';

const Wrapper = styled.div`
  ${space}

  display: flex;
  flex-direction: row;
  border-bottom: 2px solid ${themeGet('colors.primary_50')};
  background: ${themeGet('colors.white')};

  & > img {
    width: 40px;
    min-width: 40px;
    height: 66px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: ${themeGet('space.xs')}px;
`;

const Cover = styled.img`
  border-radius: 4px;
`;

function CardPlaceholder({ style }) {
  const { languageCode } = useSelector((state) => state.config);
  return (
    <Wrapper p="xs" mb="xs" {...style}>
      <Cover src="/images/book-placeholder.jpg" alt="Book cover" />
      <Content>
        <Text
          fontSize={['h3', 'h3', 'h3', 'h2']}
          lineHeight={['h3', 'h3', 'h3', 'h2']}
          fontWeight="normal"
          ml="xs"
        >
          {translations[languageCode]['no available']}
        </Text>
      </Content>
    </Wrapper>
  );
}

export default CardPlaceholder;
