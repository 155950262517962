import React from 'react';
import ReactTooltip from 'react-tooltip';

function CustomTooltip({ text, forData, ...others }) {
  return (
    <ReactTooltip id={forData} aria-haspopup="true" {...others}>
      {text}
    </ReactTooltip>
  );
}

export default CustomTooltip;
