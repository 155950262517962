import React, { useContext, useMemo, useCallback } from 'react';
import formatCurrency from 'lib/format-currency';
import { FormattedNumber } from 'react-intl';
import styled, { ThemeContext } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const width = 250;
const height = 100;

const Wrapper = styled.g`
  transform: ${({ isRotate }) => (isRotate ? 'rotate(180deg)' : 'rotate(0deg)')};
  transform-origin: ${({ isRotate }) => (isRotate ? `${width / 2}px ${height / 2}px` : '0px 0px')};
`;

function Label({ maxWidth, x, y, activePoints, data: areaData }) {
  const isRotate = useMemo(() => x > maxWidth / 2, [x, maxWidth]);

  const theme = useContext(ThemeContext);

  const getLabel = useCallback(
    (color) => {
      const el = areaData.find((row) => themeGet(`colors.${row.color}`)({ theme }) === color);

      return el && el.label ? el.label : '';
    },
    [areaData, theme],
  );

  const getValue = useCallback(
    ({ color, data }) => {
      if (color === 'trasparent') return '';
      const el = areaData.find((row) => themeGet(`colors.${row.color}`)({ theme }) === color);

      if (el && el.id) {
        if (el && el.id === 'revenue') {
          return formatCurrency(el.currency, Number(data));
        }

        return <FormattedNumber value={data} />;
      }

      return <FormattedNumber value={0} />;
    },
    [areaData, theme],
  );

  return (
    <svg
      x={`${isRotate ? x - width : x + 5}`}
      y={`${isRotate ? y - height / 2 - 15 : y - height / 2 + 15}`}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Wrapper isRotate={isRotate} stroke="grey">
        <polygon points="10,25 0,35 10,45" fill="white" />
        <rect width={width - 15} height={height} x="9" rx="5" ry="5" fill="white" />
      </Wrapper>
      <g>
        <text
          fill={themeGet(`colors.grey_600`)({ theme })}
          style={{
            whiteSpace: 'pre',
            fontFamily: 'inherit',
            fontSize: '16',
            letterSpacing: '0px',
            fontWeight: 'bold',
          }}
        >
          <tspan x="100" y="20">
            {activePoints[0].xName}
          </tspan>
        </text>
      </g>
      {activePoints.map(({ childName, style, y: data }, index) => (
        <g key={childName}>
          <circle cx="20" r="5" fill={style.data.stroke} cy={40 + index * 20} />
          <text
            fill={themeGet(`colors.grey_600`)({ theme })}
            style={{
              whiteSpace: 'pre',
              fontFamily: 'inherit',
              fontSize: '14',
              letterSpacing: '0px',
            }}
          >
            <tspan x="35" y="45" dy={index * 20}>
              {`${getLabel(style.data.stroke)}`}
            </tspan>
            <tspan textAnchor="end" x={width - 15} y="45" dy={index * 20}>
              {getValue({ color: style.data.stroke, data })}
            </tspan>
          </text>
        </g>
      ))}
    </svg>
  );
}

export default Label;
