import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import base64 from 'base-64';
import selectAreaDash from 'redux-api/reselect/area-chart';
import { getHistogramRequest } from 'redux-api/action/histogram';
import Area from 'theme/charts/area';
import AreaContentLoader from 'theme/content-loader/area';
import Text from 'theme/text';
import BtnLink from 'theme/link';
import translations from 'translations';

const Wrapper = styled.div`
  ${space}

  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const WrapLink = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

function AreaChart({ style, dateTo, dateFrom, dataType, dateRange, filters, interval }) {
  const [queriedKey, setQueriedKey] = useState(null);

  const dispatch = useDispatch();
  const { currency, languageCode } = useSelector((state) => state.config);
  const areaPoints = useSelector((state) => selectAreaDash(state, queriedKey));
  const { analytics_type } = useSelector((state) => state.profile.payload);

  const query = useMemo(() => {
    if (!dataType || !dateTo || !dateFrom || !interval) return null;

    let queryString = `interval=${interval}&currency=${currency}&dateRange=${dateRange}&dateTo=${dateTo}&dateFrom=${dateFrom}&dataType=${dataType}&version=7&enterprise=${
      analytics_type || 'standard'
    }`;

    if (filters) {
      queryString = `${queryString}&filters=${filters}`;
    }

    return queryString;
  }, [dateTo, dateFrom, dataType, filters, currency, interval, analytics_type]);

  const filteredAreaPoints = useMemo(() => {
    if (!areaPoints) return null;

    let filteredChart =
      dataType === 'usage_data'
        ? areaPoints.chart.filter((point) => !point.id.match(/revenue/))
        : areaPoints.chart.filter((point) => !point.id.match(/duration/));

    return {
      ...areaPoints,
      chart: filteredChart,
    };
  }, [areaPoints, dataType]);

  useEffect(() => {
    if (!query) return;

    const key = base64.encode(query);

    setQueriedKey(key);

    dispatch(getHistogramRequest({ key, query, currency }));
  }, [dispatch, query, currency]);

  const formatDates = useMemo(() => {
    moment.locale(languageCode);

    return {
      dateFrom: moment(dateFrom).format('DD MMM YYYY'),
      dateTo: moment(dateTo).format('DD MMM YYYY'),
    };
  }, [dateFrom, dateTo, languageCode]);

  return (
    <Wrapper {...style}>
      {filteredAreaPoints.chart.length > 1 ? (
        <Area
          title={translations[languageCode]['Last 30 days']}
          dateTo={formatDates.dateTo}
          dateFrom={formatDates.dateFrom}
          payload={filteredAreaPoints}
        />
      ) : (
        <AreaContentLoader />
      )}

      <WrapLink>
        <div>
          <BtnLink href="/analytics" style={{ mt: 's' }}>
            <Text
              fontSize={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
              lineHeight={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
              fontWeight={['normal', 'normal', 'normal', 'medium']}
              textAlign="center"
            >
              {translations[languageCode]['go to the analytics section']}
            </Text>
          </BtnLink>
        </div>
      </WrapLink>
    </Wrapper>
  );
}

export default AreaChart;
