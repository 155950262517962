/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { typography, color, space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import ContentLoader from 'theme/content-loader';

const Box = styled.li`
  padding: ${themeGet('space.s')}px 0;
  border-bottom: 1px solid ${themeGet('colors.primary_100')};

  display: flex;
  flex-direction: column;
  position: relative;

  & > .label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  & > .value {
    width: 100%;
  }
`;

const Text = styled.p`
  ${typography}
  ${color}
  ${space}

  display: flex;
  align-items: center;

  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: ${({ textTransform }) => textTransform || 'none'};
`;

const Value = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

function Row({ payload = { growthRates: 10 } }) {
  return (
    <Box>
      <div className="label">
        <Text
          fontSize={['h4', 'h4', 'h4', 'h2']}
          lineHeight={['h4', 'h4', 'h4', 'h2']}
          fontWeight="bold"
          color="grey_400"
          textTransform="capitalize"
        >
          {payload.label}
        </Text>
        {payload.growthRates === null ? (
          <ContentLoader
            width={50}
            height={16}
            lines={[
              {
                x: 25,
                y: 0,
                rx: 5,
                ry: 5,
                width: 25,
                height: 10,
              },
            ]}
          />
        ) : (
          <Value>
            <Text
              fontSize={['label', 'label', 'label', 'h2']}
              lineHeight={['label', 'label', 'label', 'h2']}
              fontWeight="bold"
              color={
                payload.growthRates === 0
                  ? 'grey_600'
                  : payload.growthRates < 0
                  ? 'red_400'
                  : 'lime_400'
              }
            >
              {`${payload.growthRates === 0 ? '' : payload.growthRates < 0 ? '-' : '+'} ${Math.abs(
                payload.growthRates,
              )}%`}
            </Text>
          </Value>
        )}
      </div>
      <div className="value">
        <Text
          fontSize={['h3', 'h3', 'h3', 'h2']}
          lineHeight={['h3', 'h3', 'h3', 'h2']}
          fontWeight={['medium', 'medium', 'medium', 'normal']}
          color="grey_600"
        >
          {payload.value === null ? (
            <ContentLoader
              width={50}
              height={16}
              lines={[
                {
                  x: 0,
                  y: 0,
                  rx: 5,
                  ry: 5,
                  width: 50,
                  height: 12,
                },
              ]}
            />
          ) : (
            payload.value
          )}
        </Text>
      </div>
    </Box>
  );
}

export default Row;
