import React, { useCallback } from 'react';
import Checkbox from 'theme/checkbox';

function CheckBox({ payload, onChange }) {
  const handleChange = useCallback(
    (e) => {
      const {
        target: { checked },
      } = e;

      onChange({ id: payload.id, checked });
    },
    [payload, onChange],
  );

  return (
    <Checkbox
      key={payload.label}
      input={{
        onChange: handleChange,
      }}
      defaultChecked
      meta={{}}
      checkColor={payload.color}
      label={payload.label}
      style={{
        ml: 's',
      }}
    />
  );
}

export default CheckBox;
