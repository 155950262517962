/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const write = ({ disabled, width = '18', color }) => {
  const theme = useContext(ThemeContext);

  const colorMemo = useMemo(() => {
    if (color) {
      return themeGet(`colors.${color}`)({ theme });
    }

    return disabled ? 'grey_200' : themeGet(`colors.write`)({ theme });
  }, [theme, disabled, color]);

  return (
    <svg width={width} viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.17702 13.8958L4.15285 13.9095V9.86981L12.938 1.16855C13.6219 0.485548 14.8247 0.4819 15.5141 1.17129L16.9603 2.61755C17.305 2.96224 17.4947 3.42001 17.4947 3.90696C17.4947 4.39391 17.305 4.85168 16.9603 5.19637L8.17702 13.8958ZM15.6737 3.90422L14.2247 2.4607L12.7711 3.90149L14.2174 5.34683L15.6737 3.90422ZM11.4753 5.1836L5.97663 10.6303V12.0793L7.4238 12.0748L12.9216 6.62986L11.4753 5.1836ZM15.0955 17.5452H2.32909C1.32328 17.5452 0.50531 16.7273 0.50531 15.7214V2.95499C0.50531 1.94917 1.32328 1.13121 2.32909 1.13121H10.3966L8.5728 2.95499H2.32909V15.7214H5.04561C5.06034 15.7219 5.07486 15.724 5.08927 15.726C5.1052 15.7283 5.121 15.7306 5.1368 15.7306C5.14865 15.7306 5.16074 15.7283 5.17282 15.726C5.1849 15.7237 5.19698 15.7214 5.20884 15.7214H15.0955V9.64096L16.9193 7.81718V15.7214C16.9193 16.7273 16.1014 17.5452 15.0955 17.5452Z"
          fill={colorMemo}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18" fill="white" transform="translate(0 0.100098)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default write;
