import React from 'react';
import styled from 'styled-components';
import { layout, space, color, typography } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { Checkbox } from 'antd';

const Wrapper = styled.div`
  max-width: 320px;

  ${space}
  ${layout}
`;

const Label = styled.p`
  ${space}
  ${color}
  ${typography}
`;

const WrapCheckbox = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  & > .ant-checkbox-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    display: inline-block;
    line-height: unset;
    cursor: pointer;
  }

  & > .ant-checkbox-wrapper > .ant-checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    top: -0.09em;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    outline: none;
    cursor: pointer;
    border: 2px solid ${({ checkColor }) => themeGet(`colors.${checkColor || 'grey_400'}`)};
    border-radius: 2px;
  }

  & > .ant-checkbox-wrapper > .ant-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border-radius: 2px;
    visibility: hidden;
    animation: antCheckboxEffect 0.36s ease-in-out;
    animation-fill-mode: backwards;
    content: '';
  }

  & > .ant-checkbox-wrapper > .ant-checkbox > .ant-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  & > .ant-checkbox-wrapper > .ant-checkbox > .ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border-collapse: separate;
    transition: all 0.3s;
    border-radius: 0;
    border: none;

    &:after {
      position: absolute;
      top: 50%;
      left: 21%;
      display: table;
      width: 7px;
      height: 14px;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(0) translate(-50%, -50%);
      opacity: 0;
      transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
      content: ' ';
    }
  }

  & > .ant-checkbox-wrapper > .ant-checkbox-checked > .ant-checkbox-inner {
    background-color: ${({ checkColor }) => themeGet(`colors.${checkColor || 'grey_400'}`)};

    &:after {
      position: absolute;
      display: table;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: ' ';
    }
  }

  & > .ant-checkbox-wrapper > .ant-checkbox-checked {
    &:after {
      border: none;
    }
  }
`;

const WrapError = styled.div`
  ${color}
  display: flex;
  width: 100%;
  align-items: baseline;
  line-height: 16px;
`;

const Message = styled.p`
  ${typography}
  ${space}
  line-height: 1.5em;
`;

function MyCheckbox({
  fontSize,
  defaultChecked,
  checkColor,
  disabled,
  style,
  input,
  meta,
  size,
  label,
  error,
}) {
  return (
    <Wrapper {...style}>
      <WrapCheckbox size={size} checkColor={disabled ? 'primary_200' : checkColor}>
        <Checkbox
          {...input}
          onChange={input.onChange}
          defaultChecked={defaultChecked}
          disabled={disabled}
        />
        <Label
          fontSize={fontSize || ['pSmall', 'pSmall', 'pSmall', 'h3']}
          lineHeight={['pSmall', 'pSmall', 'pSmall', 'h3']}
          fontWeight="normal"
          color="grey_600"
          ml="xs"
          dangerouslySetInnerHTML={{ __html: label }}
        />
      </WrapCheckbox>

      <WrapError color="red_400">
        {meta.error && meta.touched && (
          <Message m={0} fontSize={1}>
            {error}
          </Message>
        )}
      </WrapError>
    </Wrapper>
  );
}

export default MyCheckbox;
