/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const distribute = ({ disabled, width = '18', color }) => {
  const theme = useContext(ThemeContext);

  const colorMemo = useMemo(() => {
    if (color) {
      return themeGet(`colors.${color}`)({ theme });
    }

    return disabled ? 'grey_200' : themeGet(`colors.distribute`)({ theme });
  }, [theme, disabled, color]);

  return (
    <svg width={width} viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.8 0.947266H16.2C17.1927 0.947266 18 1.75457 18 2.74727V4.54727C18 5.20967 17.6355 5.78477 17.1 6.09707V15.3473C17.1 16.34 16.2927 17.1473 15.3 17.1473H2.7C1.7073 17.1473 0.9 16.34 0.9 15.3473V6.09707C0.3645 5.78477 0 5.20967 0 4.54727V2.74727C0 1.75457 0.8073 0.947266 1.8 0.947266ZM16.2 2.74727H1.8V4.54727H16.2018L16.2 2.74727ZM2.7 6.34727V15.3473H15.3018L15.3 6.34727H2.7ZM12.6 8.14736H5.40001V9.94736H12.6V8.14736Z"
          fill={colorMemo}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18" fill="white" transform="translate(0 0.0473633)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default distribute;
