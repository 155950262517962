/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { space, typography, color, layout } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import translations from 'translations';

const Wrapper = styled.a`
  ${space}

  cursor: pointer;

  display: flex;
  flex-direction: row;
  border-bottom: 2px solid ${themeGet('colors.primary_50')};
  text-decoration: none;

  & > img {
    width: 40px;
    min-width: 40px;
    height: 66px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: ${themeGet('space.xs')}px;
  text-decoration: none;

  & > .cta {
    color: ${themeGet('colors.primary_400')};

    & > :hover {
      color: ${themeGet('colors.primary_300')};
    }
  }
`;

const Text = styled.p`
  ${typography}
  ${color}
  ${space}
  ${layout}

  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  -webkit-line-clamp: 2;
`;

const Cover = styled.img`
  border-radius: 4px;
`;

const Value = styled.div`
  ${color}
  ${space}

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

function TopSellingCard({ payload, error, onClick }) {
  const { languageCode } = useSelector((state) => state.config);

  const handleClick = useCallback(() => {
    onClick(payload.isbn);
  }, [onClick, payload]);

  return (
    <Wrapper title={payload.title} onClick={handleClick} p="xs" mb="xs">
      <Cover
        src={error ? '/images/book-error.jpg' : payload.cover || '/images/book.jpg'}
        alt="copertina"
      />
      <Content>
        <Text
          fontSize={['pSmall', 'pSmall', 'pSmall', 'h3']}
          lineHeight={['pSmall', 'pSmall', 'pSmall', 'h3']}
          fontWeight="bold"
          color="grey_600"
        >
          {!error ? payload.title : 'error'}
        </Text>
        <Text
          fontSize={['pSmall', 'pSmall', 'pSmall', 'h3']}
          lineHeight={['pSmall', 'pSmall', 'pSmall', 'h3']}
          fontWeight="normal"
          color="grey_600"
          mt="xs"
        >
          {translations[languageCode].Revenues}
        </Text>
        <ContentRow>
          <Text
            fontSize="display"
            lineHeight="display"
            fontWeight="normal"
            color="grey_400"
            textAlign="left"
          >
            {payload.totalCurrentRevenues}
          </Text>
          <Value ml="xxs">
            <Text
              fontSize="label"
              lineHeight="label"
              fontWeight="bold"
              color={
                payload.growthRates === 0
                  ? 'grey_600'
                  : payload.growthRates < 0
                  ? 'red_400'
                  : 'lime_400'
              }
              textAlign="right"
            >
              {`${
                payload.percentEarning === 0 ? '' : payload.percentEarning < 0 ? '-' : '+'
              } ${Math.abs(payload.percentEarning)}%`}
            </Text>
          </Value>
        </ContentRow>
      </Content>
    </Wrapper>
  );
}

export default TopSellingCard;
