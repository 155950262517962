/* eslint-disable camelcase */
import { createSelector } from 'reselect';

const selectPublishingHistory = createSelector(
  (state) => state.history.payload,
  (payload) => {
    const { data } = payload;

    if (!data) return [];

    return data.items.map((item) => {
      let result = {};

      const { isbn, title, cover_url, platforms } = item;

      platforms.forEach((platform) => {
        result.platforms = {
          ...result.platforms,
          [platform.name]: platform,
        };
      });

      result = {
        ...result,
        id: isbn,
        title,
        cover: cover_url,
      };

      return result;
    });
  },
);

export default selectPublishingHistory;
