/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import { typography, color, space } from 'styled-system';
import { useSelector } from 'react-redux';
import selectTopAssets from 'redux-api/reselect/top-assets';
import Pie from 'theme/charts/pie';
import ContentLoader from 'theme/content-loader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelWrap = styled.div`
  ${space}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  ${typography}
  ${color}
  ${space}

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`;

const Value = styled.div`
  ${color}
  ${space}

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

function Chart({ id, selected, withLabel, maxWidth }) {
  const pieAssets = useSelector((state) => selectTopAssets(state, id));

  return (
    <Wrapper>
      <Pie data={pieAssets.pieChartData} maxWidth={maxWidth || '125'} selected={selected} />
      {withLabel && (
        <LabelWrap mt="m">
          <Text
            fontSize="h2"
            lineHeight="h2"
            fontWeight="bold"
            color="grey_600"
            textAlign="right"
            mr="s"
          >
            {pieAssets.value === null ? (
              <ContentLoader
                width={100}
                height={16}
                lines={[
                  {
                    x: 0,
                    y: 0,
                    rx: 5,
                    ry: 5,
                    width: 100,
                    height: 12,
                  },
                ]}
              />
            ) : (
              pieAssets.value
            )}
          </Text>
          {pieAssets.percent === null ? (
            <ContentLoader
              width={50}
              height={16}
              lines={[
                {
                  x: 0,
                  y: 0,
                  rx: 5,
                  ry: 5,
                  width: 25,
                  height: 10,
                },
              ]}
            />
          ) : (
            <Value>
              <Text
                fontSize="pSmall"
                lineHeight="pSmall"
                fontWeight="bold"
                color={
                  pieAssets.percent > 0
                    ? 'lime_400'
                    : pieAssets.percent < 0
                    ? 'red_400'
                    : 'grey_600'
                }
                textAlign="right"
              >
                {`${pieAssets.percent > 0 ? '+' : pieAssets.percent < 0 ? '-' : ''} ${Math.abs(
                  pieAssets.percent,
                )}%`}
              </Text>
            </Value>
          )}
        </LabelWrap>
      )}
    </Wrapper>
  );
}

export default Chart;
