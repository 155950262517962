import React, { useEffect } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { color, space, typography } from 'styled-system';
import { useDispatch, useSelector } from 'react-redux';
import { getNewsRequest } from 'redux-api/action/news';
import selectNews from 'redux-api/reselect/news';
import Card from 'theme/card';
import Text from 'theme/text';
import translations from 'translations';
import Tags from './tags';

const Wrapper = styled.div`
  width: 100%;

  .title {
    padding: 0 0 ${themeGet('space.m')}px ${themeGet('space.xs')}px;
  }

  .cards {
    display: flex;
    flex-direction: row;

    & > div {
      padding: 0 ${themeGet('space.xs')}px;
      width: 33%;
    }
  }
`;

const TextEllipsis = styled.p`
  ${typography}
  ${color}
  ${space}

  height: ${({ line }) => line * 22}px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  -webkit-line-clamp: ${({ line }) => line};

  @media screen and (min-width: 1440px) {
    height: ${({ line }) => line * 30}px;
  }
`;

const TextSubTitle = styled.p`
  ${typography}
  ${color}
  ${space}

  width: 0;
  min-width: 100%;
  height: ${({ line }) => line * 22}px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  -webkit-line-clamp: ${({ line }) => line};
`;

const Link = styled.a`
  text-decoration: none;
`;

function NewsCards() {
  const dispatch = useDispatch();

  const { languageCode } = useSelector((state) => state.config);

  const news = useSelector(selectNews);

  useEffect(() => {
    dispatch(getNewsRequest());
  }, [dispatch, languageCode]);

  return (
    <Wrapper>
      <div className="title">
        <Text
          fontSize={['h3', 'h3', 'h3', 'h2']}
          lineHeight={['h3', 'h3', 'h3', 'h2']}
          fontWeight="bold"
          color="grey_600"
          textAlign="left"
        >
          {translations[languageCode]['latest news']}
        </Text>
      </div>
      <div className="cards">
        {news.map((item, index) => (
          <div key={item.id}>
            <Card
              width="100%"
              minHeight="100%"
              bg={index === 0 ? 'primary_50' : 'white'}
              borderColor={index === 0 ? 'primary_400' : 'primary_100'}
              borderWidth={index === 0 ? '2px' : '1px'}
              py="m"
              px="s"
            >
              <Link title={item.title} href={item.link} target="_blank" rel="noreferrer">
                <Text
                  fontSize={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
                  lineHeight={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
                  fontWeight="normal"
                  color="grey_400"
                  mb="xs"
                >
                  {item.publishedAt}
                </Text>
                <TextEllipsis
                  fontSize={['pLarge', 'pLarge', 'pLarge', 'h2']}
                  lineHeight={['pLarge', 'pLarge', 'pLarge', 'h2']}
                  fontWeight="bold"
                  color="grey_600"
                  mb="xs"
                  line="2"
                >
                  {item.title}
                </TextEllipsis>
                <TextSubTitle
                  fontSize={['pLarge', 'pLarge', 'pLarge', 'h3']}
                  lineHeight={['pLarge', 'pLarge', 'pLarge', 'h3']}
                  fontWeight="normal"
                  color="grey_400"
                  line="3"
                  dangerouslySetInnerHTML={{ __html: item.subtitle }}
                />
              </Link>

              <Tags payload={item.tags} />
            </Card>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}

export default NewsCards;
