/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';
import { space, typography, color, layout } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import Distribute from 'theme/icons/distribute';
import Print from 'theme/icons/print';

const Wrapper = styled.div`
  ${space}

  display: flex;
  flex-direction: row;
  border-bottom: 2px solid ${themeGet('colors.primary_50')};
  background: ${themeGet('colors.white')};

  & > img {
    width: 40px;
    min-width: 40px;
    height: 66px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: ${themeGet('space.xs')}px;

  & > .actions {
    display: flex;
    flex-direction: row;
    width: 100%;

    & > .action-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 38px;
      margin-right: ${themeGet('space.xxs')}px;
      cursor: pointer;
    }

    & > span.action-btn {
      cursor: default;
    }
  }
`;

const Text = styled.p`
  ${typography}
  ${color}
  ${space}
  ${layout}

  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  -webkit-line-clamp: 2;
`;

const Cover = styled.img`
  border-radius: 4px;
`;

/* WRITE WAS REMOVED WE NEED TO COMPLETE API FOR IT */
/*
  {payload.platforms.WRITE.item_url ? (
    <a className="action-btn" title="write" href={payload.platforms.WRITE.item_url}>
      <Write />
    </a>
  ) : (
    <span className="action-btn">
      <Write color="grey_200" />
    </span>
  )}
*/

function PublishCard({ style, payload, error }) {
  return (
    <Wrapper p="xs" mb="xs" {...style}>
      <Cover
        src={error ? '/images/book-error.jpg' : payload.cover || '/images/book.jpg'}
        alt="copertina"
      />
      <Content>
        <div className="actions">
          {payload.platforms.PUBLISH.item_url ? (
            <a className="action-btn" title="distribute" href={payload.platforms.PUBLISH.item_url}>
              <Distribute />
            </a>
          ) : (
            <span className="action-btn">
              <Distribute color="grey_200" />
            </span>
          )}
          {payload.platforms.POS.item_url ? (
            <a className="action-btn" title="print" href={payload.platforms.POS.item_url}>
              <Print />
            </a>
          ) : (
            <span className="action-btn">
              <Print color="grey_200" />
            </span>
          )}
        </div>
        <Text
          fontSize={['pSmall', 'pSmall', 'pSmall', 'h3']}
          lineHeight={['pSmall', 'pSmall', 'pSmall', 'h3']}
          fontWeight="normal"
          ml="xs"
          height={['40px', '40px', '40px', '42px']}
          color="grey_600"
        >
          {!error ? payload.title : 'error'}
        </Text>
      </Content>
    </Wrapper>
  );
}

export default PublishCard;
