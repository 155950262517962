/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { color, space } from 'styled-system';
import { useSelector, useDispatch } from 'react-redux';
import { getBillingSummaryRequest } from 'redux-api/action/billing';
import selectSummary from 'redux-api/reselect/billing';
import selectAuth from 'redux-api/reselect/auth';
import Card from 'theme/card';
import Text from 'theme/text';
import CardIcon from 'theme/icons/card';
import ContentLoader from 'theme/content-loader';
import translations from 'translations';

const Content = styled.div`
  display: flex;
  flex-direction: column;

  & > .card-percent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: ${themeGet('space.s')}px;

    & > svg {
      ${color}
    }
  }
`;

const Value = styled.div`
  ${color}
  ${space}

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

function LastInvoice() {
  const dispatch = useDispatch();
  const { adminRequestedStreetlibInternalId } = useSelector(selectAuth);
  const payload = useSelector(selectSummary);
  const { languageCode } = useSelector((state) => state.config);

  useEffect(() => {
    dispatch(getBillingSummaryRequest());
  }, [dispatch, adminRequestedStreetlibInternalId]);

  return (
    <Card width="33.33%" bg="grey_100" borderColor="pie_1_3" pt="l" pb="l" ml="s">
      <Content color="pie_1_3">
        <div className="card-percent">
          <CardIcon
            style={{
              width: ['24px', '24px', '24px', '48px'],
            }}
          />
          <Value pl="s">
            <Text
              fontSize={['h2', 'h2', 'h2', 'h2Large']}
              lineHeight={['h2', 'h2', 'h2', 'h2Large']}
              fontWeight="bold"
              color={
                payload.earningsPercentage > 0
                  ? 'lime_400'
                  : payload.earningsPercentage < 0
                  ? 'red_400'
                  : 'grey_600'
              }
            >
              {`${
                payload.earningsPercentage > 0 ? '+' : payload.earningsPercentage < 0 ? '-' : ''
              } ${Math.abs(payload.earningsPercentage)}%`}
            </Text>
          </Value>
        </div>
        <Text
          fontSize={['display', 'display', 'display', 'h2']}
          lineHeight={['display', 'display', 'display', 'h2']}
          fontWeight="bold"
          color="grey_600"
          textAlign="center"
          mb="xxs"
        >
          {translations[languageCode]['last billed invoice']}
        </Text>
        <Text
          fontSize={['h2', 'h2', 'h2', 'h3Large']}
          lineHeight={['h2', 'h2', 'h2', 'h3Large']}
          fontWeight={['medium', 'medium', 'medium', 'normal']}
          color="grey_600"
          textAlign="center"
        >
          {payload.lastEarned === null ? (
            <ContentLoader
              width={50}
              height={16}
              lines={[
                {
                  x: 0,
                  y: 5,
                  rx: 5,
                  ry: 5,
                  width: 50,
                  height: 12,
                },
              ]}
            />
          ) : (
            payload.lastEarned
          )}
        </Text>
      </Content>
    </Card>
  );
}

export default LastInvoice;
