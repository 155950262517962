/* eslint-disable camelcase */
import { createSelector } from 'reselect';
import moment from 'moment';
import configSelector from './config';

const selectMedium = createSelector(
  (state) => state.news.payload,
  configSelector,
  (payload, { languageCode }) => {
    const { posts } = payload;

    if (!posts) return [];

    try {
      return posts.map((post) => {
        const { id, title, published_at, excerpt, tags, url } = post;

        moment.locale(languageCode);

        const publishedAt = moment(published_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMMM YYYY');

        return {
          id,
          title,
          tags: tags.filter((tag) => tag.visibility === 'public').slice(0, 3),
          subtitle: excerpt,
          publishedAt,
          link: url,
        };
      });
    } catch {
      return [];
    }
  },
);

export default selectMedium;
