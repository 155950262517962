import React from 'react';
import { layout, space, typography } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

const Button = styled.a`
  background: transparent;
  outline: 0;
  text-decoration: none;
  display: flex;

  & > span {
    color: ${({ isFocused }) =>
      isFocused ? themeGet('colors.primary_600') : themeGet('colors.primary_400')};
    border-radius: 12px;

    width: 100%;

    ${typography}

    display: flex;
    justify-content: center;

    &:hover {
      color: ${themeGet('colors.primary_500')};
    }

    &:focus {
      color: ${themeGet('colors.primary_600')};
    }
  }

  &:focus {
    color: transparent;
  }

  &:disabled {
    opacity: 0.5;
  }

  ${space}
  ${layout}
`;

function ExternalLink({ style, label, disabled, isWaiting, ...rest }) {
  return (
    <Button
      title={label}
      aria-label={label}
      fontSize="h4"
      lineHeight="h4"
      fontWeight="bold"
      {...style}
      disabled={disabled || isWaiting}
      {...rest}
    >
      <span>{label}</span>
    </Button>
  );
}

export default ExternalLink;
