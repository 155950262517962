import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { layout, color, typography, space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { useSelector } from 'react-redux';
import selectProfile from 'redux-api/reselect/profile';
import Plus from 'theme/icons/plus';
import Text from 'theme/text';
import Modal from 'theme/modal';
import translations from 'translations';
import Steps from './steps';

const Wrapper = styled.button`
  min-width: 140px;
  background: transparent;
  margin: 0;
  padding: 0;

  & > span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: ${themeGet('colors.primary_400')};
    color: ${themeGet('colors.white')};
    padding: 0 ${themeGet('space.s')}px;
    border-radius: 8px;

    &:hover {
      background: ${themeGet('colors.primary_300')};
    }

    &:focus {
      background: ${themeGet('colors.primary_200')};
    }

    &:disabled {
      opacity: 0.5;
    }

    ${color}
    ${typography}
  }

  ${space}
  ${layout}
`;

const StyledText = styled(Text)`
  white-space: nowrap;
`;

function AddNewContent() {
  const [isVisible, setVisible] = useState(false);

  const { languageCode } = useSelector((state) => state.config);
  const { payload: { company } } = useSelector(selectProfile);

  const handleOpen = useCallback(() => {
    setVisible(true);
  }, []);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <>
      <Wrapper
        title={translations[languageCode]['add new content']}
        href="/"
        type="button"
        onClick={handleOpen}
        width="100%"
        height={['38px', '38px', '38px', '48px']}
        mb="s"
      >
        <span>
          <Plus />
          <StyledText
            fontSize={['pLarge', 'pLarge', 'pLarge', 'h3']}
            lineHeight={['pLarge', 'pLarge', 'pLarge', 'h3']}
            fontWeight="medium"
            ml="xs"
          >
            {translations[languageCode]['add new content']}
          </StyledText>
        </span>
      </Wrapper>
      <Modal
        width="900px"
        bgColor="white"
        contentStyle={{ py: 0 }}
        isVisible={isVisible}
        onClose={handleClose}
        title={translations[languageCode]['create new content']}
        footer={
          <a
            title={translations[languageCode]['Help center']}
            href={`${company?.code === 'SL_DE' ? process.env.HELP_URI_DE : process.env.HELP_URI}`}
            className="link"
          >
            <Text fontSize="h4" lineHeight="h4" fontWeight="bold" color="grey_600">
              {translations[languageCode]['Help center']}
            </Text>
          </a>
        }
      >
        <Steps />
      </Modal>
    </>
  );
}

export default AddNewContent;
