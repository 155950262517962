import React, { useMemo } from 'react';
import styled from 'styled-components';
import { color, space, flexbox, layout } from 'styled-system';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Card from 'theme/card';
import Sidebar from 'theme/sidebar';
import Header from 'theme/header';
import AuthSwitch from 'components/auth-switch';
import CounterCards from 'components/counter-cards';
import NewsCards from 'components/news-cards';
import WarningNotification from 'components/warning-notification';
import AssetsRevenues from 'components/assets-revenues-dash';
import AreaCharts from 'components/area-chart';
import TopBar from 'components/top-bar';
import AlertMessage from 'theme/alert-message';
import translations from 'translations';
import selectProfile from 'redux-api/reselect/profile';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Content = styled.div`
  ${space}

  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const Section = styled.div`
  ${space}
  ${color}
  ${layout}

  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const WrapStats = styled.div`
  ${flexbox}

  display: flex;

  & > div:first-child {
    flex: 1;
  }
`;

function DashboardPage() {
  const { formatDate } = useSelector((state) => state.config);

  const {
    payload: { company },
  } = useSelector(selectProfile);

  const { languageCode } = useSelector((state) => state.config);

  const dateTo = useMemo(() => {
    const today = moment();

    return today.format(formatDate);
  }, [formatDate]);

  const dateFrom = useMemo(() => {
    const today = moment();

    return today.subtract(30, 'days').format(formatDate);
  }, [formatDate]);

  return (
    <AuthSwitch>
      <Wrapper>
        <TopBar />

        <Header bg="white" title="StreetLib Dashboard" />

        <div className="row">
          <Content pb="m" px="s">
            <Section maxWidth="1440px" width="100%">
              <WarningNotification
                style={{
                  p: 's',
                  mb: 'm',
                }}
              />
            </Section>

            <Section mb="m" width="100%" maxWidth="1440px">
              <CounterCards />
            </Section>

            {company?.code === 'SL_DE' && (
              <Section mb="m" width="100%" maxWidth="1440px">
                <Card width="100%" borderColor="orange_400" bg="accent_light" p="m" my="xs">
                  <AlertMessage message={translations[languageCode]['bookrix_catalog_issue']} />
                </Card>
              </Section>
            )}

            <Section mb="m" width="100%">
              <Card width="100%" bg="primary_50" border="none" py="m" px="s">
                <WrapStats flexDirection={['column', 'column', 'column', 'column', 'row']}>
                  <Card
                    width={['100%', '100%', '100%', '100%', '50%']}
                    bg="white"
                    border="none"
                    py="m"
                  >
                    <AreaCharts
                      interval="day"
                      dateTo={dateTo}
                      dateFrom={dateFrom}
                      dataType="temporary_data"
                    />
                  </Card>
                  <Card
                    width={['100%', '100%', '100%', '100%', '50%']}
                    bg="white"
                    border="none"
                    py="m"
                    px="s"
                    mt={['s', 's', 's', 's', '0']}
                    ml={['0', '0', '0', '0', 's']}
                  >
                    <AssetsRevenues dateTo={dateTo} dateFrom={dateFrom} dataType="temporary_data" />
                  </Card>
                </WrapStats>
              </Card>
            </Section>
            <Section mb="m" width="100%" maxWidth="1440px">
              <NewsCards />
            </Section>
          </Content>
          <Sidebar />
        </div>
      </Wrapper>
    </AuthSwitch>
  );
}

export default DashboardPage;
