/* eslint-disable no-nested-ternary */
import React from 'react';
import { layout } from 'styled-system';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { useSelector } from 'react-redux';
import { selectServices } from 'redux-api/reselect/profile';
import ContentLoader from 'theme/content-loader';
import Card from 'theme/card';
import Text from 'theme/text';
import BtnLink from 'theme/link';
import BtnLinkExternal from 'theme/link/external-link';
import ToolTip from 'theme/tooltip';
import InfoCircle from 'theme/icons/info-circle';
import translations from 'translations';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ServiceCard = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

  & > .card-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    & > .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    & > .bottom {
      & > .divider {
        width: 100%;
        height: 1px;
        background-color: ${themeGet('colors.primary_100')};
      }

      & > .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: ${themeGet('space.xs')}px;
        flex-wrap: wrap;

        & > div {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        & > .footer-link {
          flex: 1;
        }
      }
    }
  }
`;

const WrapStatusLabel = styled.div`
  ${layout}
`;

const CircleStatus = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: ${({ isCompleted }) =>
    isCompleted ? themeGet('colors.primary_100') : themeGet('colors.grey_100')};
  display: flex;
  justify-content: center;
  align-items: center;

  ::after {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: ${({ isCompleted }) =>
      isCompleted ? themeGet('colors.primary_400') : themeGet('colors.grey_200')};
  }
`;

const WrapInfoBox = styled.div`
  cursor: pointer;
  color: ${themeGet('colors.grey_400')};
`;

const StyledText = styled(Text)`
  white-space: nowrap;
`;

function ServiceCards() {
  const { languageCode } = useSelector((state) => state.config);
  const { isGetWaiting } = useSelector((state) => state.profile);

  const { services } = useSelector(selectServices);

  return (
    <Wrapper>
      {isGetWaiting && services.length === 0 ? (
        <ContentLoader
          width={200}
          height={230}
          lines={[
            {
              x: 0,
              y: 0,
              rx: 5,
              ry: 5,
              width: 80,
              height: 20,
            },
            {
              x: 0,
              y: 30,
              rx: 5,
              ry: 5,
              width: 200,
              height: 90,
            },
          ]}
        />
      ) : (
        services.map(
          (service) =>
            service.platform.label !== 'StreetLib' && (
              <Card
                key={service.platform.label}
                width="100%"
                bg={service.enabled && !service.isNotSignable ? 'white' : 'primary_50'}
                borderColor={
                  service.isNotSignable
                    ? 'primary_100'
                    : service.enabled
                    ? 'primary_400'
                    : 'primary_200'
                }
                borderWidth={service.isNotSignable ? '1px' : service.enabled ? '2px' : '1px'}
                py="xs"
                px="s"
                my="xs"
              >
                <ServiceCard>
                  <div className="card-content">
                    <div className="header">
                      <Text
                        fontSize="h3"
                        lineHeight="h3"
                        fontWeight="bold"
                        color={service.isNotSignable ? 'grey_400' : 'grey_600'}
                      >
                        {service.platform.label}
                      </Text>

                      <div>
                        <WrapStatusLabel display={['none', 'none', 'none', 'block']}>
                          <Text
                            fontSize="pSmall"
                            lineHeight="pSmall"
                            fontWeight="medium"
                            color="grey_600"
                            mr="xs"
                          >
                            {service.isNotSignable
                              ? translations[languageCode].disabled
                              : service.enabled
                              ? translations[languageCode].Enabled
                              : translations[languageCode]['Not enabled']}
                          </Text>
                        </WrapStatusLabel>

                        <CircleStatus isCompleted={!service.isNotSignable && service.enabled} />
                      </div>
                    </div>

                    <div className="bottom">
                      <Text
                        fontSize="pLarge"
                        lineHeight="pLarge"
                        fontWeight="medium"
                        mt="xs"
                        color="grey_400"
                      >
                        {service.platform.description}
                      </Text>

                      <div className="footer">
                        <div className="footer-link">
                          {service.isNotSignable ? (
                            <WrapInfoBox>
                              <ToolTip
                                forData={`${service.platform.label}`}
                                text={translations[languageCode].not_signable}
                              />
                              <div data-tip data-for={`${service.platform.label}`}>
                                <InfoCircle />
                              </div>
                            </WrapInfoBox>
                          ) : service.enabled ? (
                            <BtnLinkExternal
                              target="_blank"
                              rel="noreferrer"
                              label={translations[languageCode]['Go to the service']}
                              href={service.platform.url}
                            />
                          ) : (
                            <BtnLink
                              style={{ p: 0 }}
                              href={`/enable-service?id=${service.platform.name}`}
                              title={translations[languageCode].Request}
                            >
                              <StyledText
                                fontSize="pLarge"
                                lineHeight="pLarge"
                                fontWeight="bold"
                                color="primary_400"
                              >
                                {translations[languageCode].Request}
                              </StyledText>
                            </BtnLink>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </ServiceCard>
              </Card>
            ),
        )
      )}
    </Wrapper>
  );
}

export default ServiceCards;
