import React, { useContext, useMemo, useState, useCallback, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { typography, color, space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import {
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryAxis,
  VictoryGroup,
  VictoryArea,
  VictoryChart,
} from 'victory';
import useDimensions from 'react-use-dimensions';
import Label from './label';
import Checkbox from './checkbox';

const Wrapper = styled.div`
  width: 100%;
  z-index: 900;

  .VictoryContainer {
    & > svg {
      overflow: visible;
    }
  }

  @media only screen and (max-width: 768px) {
    .VictoryContainer {
      user-select: auto !important;
      touch-action: auto !important;
    }
  }
`;

const Text = styled.p`
  ${typography}
  ${color}
  ${space}

  white-space: nowrap;
  text-transform: capitalize;
`;

const Header = styled.div`
  ${space}

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > .checkboxes {
    display: flex;
    flex-direction: row;
  }
`;

function Area({ title, dateFrom, dateTo, payload: { chart } }) {
  const [filter, setFilter] = useState({});

  const [ref, { width }] = useDimensions();
  const theme = useContext(ThemeContext);

  const xAxisLength = useMemo(() => {
    if (chart.length === 0) return 0;
    if (chart[0].data.length <= 6) return 1;

    return Math.round(chart[0].data.length / 6);
  }, [chart]);

  const maxAxisY = useMemo(() => {
    if (chart.length === 0) return 0;

    const maxAxis = chart.reduce((max, item) => Math.max(max, item.maxAxisY), -Infinity);

    return maxAxis;
  }, [chart]);

  const yAxisPadding = useMemo(() => {
    if (maxAxisY === 0) return 0;

    return -20 - maxAxisY.toString().length * 5;
  }, [maxAxisY]);

  const PageWidth = useMemo(() => width, [width]);

  const handleChangeFilter = useCallback(
    (value) => {
      setFilter({ ...filter, [value.id]: value.checked });
    },
    [filter],
  );

  useEffect(() => {
    let filterObj = {};

    chart.forEach((el) => {
      filterObj = { ...filterObj, [el.id]: true };
    });

    setFilter(filterObj);
  }, [chart]);

  return (
    <Wrapper ref={ref}>
      <Header px="s">
        <div>
          <Text
            fontSize={['pSmall', 'pSmall', 'pSmall', 'h2']}
            lineHeight={['pSmall', 'pSmall', 'pSmall', 'h2']}
            fontWeight="bold"
            color="grey_600"
          >
            {title}
          </Text>
          <Text
            fontSize={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
            lineHeight={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
            fontWeight="normal"
            color="grey_400"
          >
            {dateFrom} -{dateTo}
          </Text>
        </div>
        <div className="checkboxes">
          {chart.map((el) => (
            <Checkbox key={el.id} payload={el} onChange={handleChangeFilter} />
          ))}
        </div>
      </Header>

      <VictoryChart
        height={350}
        width={PageWidth}
        animate={{ duration: 1000 }}
        domainPadding={{ x: 0, y: 30 }}
        containerComponent={
          <VictoryVoronoiContainer
            voronoiBlacklist={['parent']}
            voronoiDimension="x"
            labels={() => ' '}
            labelComponent={
              <VictoryTooltip
                cornerRadius={4}
                flyoutStyle={{ fill: 'white', stroke: 'transparent' }}
                style={{
                  fontSize: 13,
                  fontFamily: 'inherit',
                  lineHeight: '20px',
                  fill: themeGet('colors.grey_600')({ theme }),
                }}
                flyoutComponent={<Label data={chart} maxWidth={PageWidth} />}
              />
            }
          />
        }
        padding={{ left: 0, right: 0, top: 0, bottom: 50 }}
      >
        <VictoryAxis
          tickFormat={(t, index) => (index % xAxisLength === 0 ? t : '')}
          style={{
            axis: { stroke: 'transparent' },
            tickLabels: {
              fontFamily: 'inherit',
              fontSize: 13,
              padding: 12,
              angle: 35,
              verticalAnchor: 'end',
              textAnchor: 'start',
            },
          }}
        />
        <VictoryAxis
          dependentAxis
          style={{
            grid: { stroke: 'grey', strokeDasharray: '4, 8', strokeWidth: 0.5 },
            axis: { stroke: 'transparent' },
            tickLabels: {
              fontFamily: 'inherit',
              textAnchor: 'end',
              fontSize: 13,
              padding: yAxisPadding,
            },
          }}
        />
        <VictoryGroup
          style={{
            data: { strokeWidth: 2, fillOpacity: 0.5 },
          }}
        >
          {chart.map((el) => (
            <VictoryArea
              key={el.color}
              style={{
                data: {
                  fill: filter[el.id] ? themeGet(`colors.${el.color}`)({ theme }) : 'trasparent',
                  stroke: filter[el.id] ? themeGet(`colors.${el.color}`)({ theme }) : 'trasparent',
                },
              }}
              data={el.data}
              // data accessor for x values
              x="date"
              y={(d) => (filter[el.id] ? d.y : 0)}
              interpolation="natural"
            />
          ))}
        </VictoryGroup>
      </VictoryChart>
    </Wrapper>
  );
}

export default Area;
