/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { color, space } from 'styled-system';
import { FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectTotalUnits } from 'redux-api/reselect/units';
import selectRevenue from 'redux-api/reselect/revenue';
import Card from 'theme/card';
import Text from 'theme/text';
import BookIcon from 'theme/icons/book';
import ContentLoader from 'theme/content-loader';
import translations from 'translations';

const Content = styled.div`
  display: flex;
  flex-direction: column;

  & > .card-percent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: ${themeGet('space.s')}px;

    & > svg {
      ${color}
    }
  }
`;

const Value = styled.div`
  ${color}
  ${space}

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

function TemporaryUnits({ queriedKey }) {
  const { languageCode } = useSelector((state) => state.config);
  const totals = useSelector((state) => selectTotalUnits(state, queriedKey));
  const temporaryUnits = useSelector((state) => selectRevenue(state, queriedKey));

  return (
    <Card width="33.33%" bg="grey_100" borderColor="grey_200" pt="l" pb="l" ml="s">
      <Content color="grey_200">
        <div className="card-percent">
          <BookIcon
            style={{
              width: ['24px', '24px', '24px', '45.5px'],
            }}
          />
          <Value pl="s">
            <Text
              fontSize={['h2', 'h2', 'h2', 'h2Large']}
              lineHeight={['h2', 'h2', 'h2', 'h2Large']}
              fontWeight="bold"
              color={
                temporaryUnits.percent > 0
                  ? 'lime_400'
                  : temporaryUnits.percent < 0
                  ? 'red_400'
                  : 'grey_600'
              }
            >
              {`${
                temporaryUnits.percent > 0 ? '+' : temporaryUnits.percent < 0 ? '-' : ''
              } ${Math.abs(temporaryUnits.percent)}%`}
            </Text>
          </Value>
        </div>
        <Text
          fontSize={['display', 'display', 'display', 'h2']}
          lineHeight={['display', 'display', 'display', 'h2']}
          fontWeight="bold"
          color="grey_600"
          textAlign="center"
          mb="xxs"
        >
          {translations[languageCode]['temporary units']}
        </Text>
        <Text
          fontSize={['h2', 'h2', 'h2', 'h3Large']}
          lineHeight={['h2', 'h2', 'h2', 'h3Large']}
          fontWeight={['medium', 'medium', 'medium', 'normal']}
          color="grey_600"
          textAlign="center"
        >
          {totals.quantity === null ? (
            <ContentLoader
              width={50}
              height={16}
              lines={[
                {
                  x: 0,
                  y: 5,
                  rx: 5,
                  ry: 5,
                  width: 50,
                  height: 12,
                },
              ]}
            />
          ) : (
            <FormattedNumber value={totals.quantity} />
          )}
        </Text>
      </Content>
    </Card>
  );
}

export default TemporaryUnits;
