/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const print = ({ disabled, width = '18', color }) => {
  const theme = useContext(ThemeContext);

  const colorMemo = useMemo(() => {
    if (color) {
      return themeGet(`colors.${color}`)({ theme });
    }

    return disabled ? 'grey_200' : themeGet(`colors.print`)({ theme });
  }, [theme, disabled, color]);

  return (
    <svg width={width} viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4 4.54736H15.3C16.7886 4.54736 18 5.75876 18 7.24736V13.5474C18 14.5401 17.1927 15.3474 16.2 15.3474H14.4V18.0474H3.6V15.3474H1.8C0.8073 15.3474 0 14.5401 0 13.5474V7.24736C0 5.75876 1.2114 4.54736 2.7 4.54736H3.6V0.0473633H14.4V4.54736ZM12.6 1.84736H5.4V4.54736H12.6V1.84736ZM5.4 16.2474H12.6V12.6474H5.4V16.2474ZM14.4 13.5474H16.2V7.24736C16.2 6.75146 15.7968 6.34736 15.3 6.34736H2.7C2.2041 6.34736 1.8 6.75146 1.8 7.24736V13.5474H3.6V10.8474H14.4V13.5474ZM14.4 7.24746H10.8V9.04746H14.4V7.24746Z"
          fill={colorMemo}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18" fill="white" transform="translate(0 0.0473633)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default print;
