import React from 'react';
import { border, layout, color, typography, space, flexbox } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

const StyledA = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  min-width: 140px;
  background: ${themeGet('colors.white')};
  color: ${themeGet('colors.grey_600')};
  padding: 0 ${themeGet('space.s')}px;
  border: 1px solid;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: ${themeGet('colors.primary_50')};
  }

  &:focus {
    background: ${themeGet('colors.primary_50')};
  }

  &:disabled {
    opacity: 0.5;
  }

  ${flexbox}
  ${layout}
  ${color}
  ${space}
  ${typography}
  ${border}
`;

function CustomLink({ title, children, type, href, disabled, style }) {
  return (
    <StyledA
      href={href}
      title={title}
      fontSize="h4"
      lineHeight="h4"
      fontWeight="bold"
      type={type}
      disabled={disabled}
      {...style}
    >
      {children}
    </StyledA>
  );
}

export default CustomLink;
