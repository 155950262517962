import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import ContentLoader from 'react-content-loader';
import Card from 'theme/card';

function CardContentLoader({ maxWidth, speed, backgroundColor, foregroundColor }) {
  const theme = useContext(ThemeContext);

  return (
    <>
      <Card
        maxWidth={maxWidth}
        width="100%"
        height="96px"
        bg="white"
        border="none"
        p="xs"
        mb="xxxs"
      >
        <ContentLoader
          speed={speed || 2}
          width="220"
          height="84"
          viewBox="0 0 220 84"
          backgroundColor={backgroundColor || themeGet(`colors.primary_100`)({ theme })}
          foregroundColor={foregroundColor || themeGet(`colors.primary_50`)({ theme })}
        >
          <rect x="0" y="0" rx="0" ry="0" width="40" height="66" />
          <rect x="66" y="20" rx="5" ry="5" width="120" height="12" />
          <rect x="66" y="40" rx="5" ry="5" width="50" height="12" />
        </ContentLoader>
      </Card>
      <Card
        maxWidth={maxWidth}
        width="100%"
        height="96px"
        bg="white"
        border="none"
        p="xs"
        mb="xxxs"
      >
        <ContentLoader
          speed={speed || 2}
          width="220"
          height="84"
          viewBox="0 0 220 84"
          backgroundColor={backgroundColor || themeGet(`colors.primary_100`)({ theme })}
          foregroundColor={foregroundColor || themeGet(`colors.primary_50`)({ theme })}
        >
          <rect x="0" y="0" rx="0" ry="0" width="40" height="66" />
          <rect x="66" y="20" rx="5" ry="5" width="120" height="12" />
          <rect x="66" y="40" rx="5" ry="5" width="50" height="12" />
        </ContentLoader>
      </Card>
      <Card
        maxWidth={maxWidth}
        width="100%"
        height="96px"
        bg="white"
        border="none"
        p="xs"
        mb="xxxs"
      >
        <ContentLoader
          speed={speed || 2}
          width="220"
          height="84"
          viewBox="0 0 220 84"
          backgroundColor={backgroundColor || themeGet(`colors.primary_100`)({ theme })}
          foregroundColor={foregroundColor || themeGet(`colors.primary_50`)({ theme })}
        >
          <rect x="0" y="0" rx="0" ry="0" width="40" height="66" />
          <rect x="66" y="20" rx="5" ry="5" width="120" height="12" />
          <rect x="66" y="40" rx="5" ry="5" width="50" height="12" />
        </ContentLoader>
      </Card>
    </>
  );
}

export default CardContentLoader;
