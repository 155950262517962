const ChangeCover = (cover) => {
  if (!cover) return cover;

  let changedCoverUrl = cover;

  if (changedCoverUrl.match(/^http:\/\/stealth-test-files/i)) {
    changedCoverUrl = changedCoverUrl.replace(
      'http://stealth-test-files',
      'https://stealth-test-files.s3-eu-west-1.amazonaws.com',
    );
  }

  const regexPublish = /^(http|https):\/\/storage.publish.streetlib.com(.s3.amazonaws.com|)/;
  if (changedCoverUrl.match(regexPublish)) {
    changedCoverUrl = changedCoverUrl.replace(regexPublish, 'https://s3-publish.streetlib.com');
  }

  const regexSbf = /^(http|https):\/\/storage.sbfstealth.com(.s3.amazonaws.com|)/;
  if (changedCoverUrl.match(regexSbf)) {
    changedCoverUrl = changedCoverUrl.replace(regexSbf, 'https://s3-publish.streetlib.com');
  }

  return changedCoverUrl;
};

export default ChangeCover;
