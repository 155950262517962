import React from 'react';
import styled from 'styled-components';
import { color, space, layout } from 'styled-system';
import { useSelector } from 'react-redux';
import PublishingHistory from 'components/publishing-history';
import TopSelling from 'components/top-selling';
import AddNewContent from 'components/add-new-content';
import ServicesCards from 'components/dashboard/service-cards';
import Text from 'theme/text';
import translations from 'translations';

const Wrapper = styled.div`
  ${space}
  ${color}
  ${layout}

  display: flex;
  flex-direction: column;
  min-width: 236px;
`;

function Sidebar() {
  const { languageCode } = useSelector((state) => state.config);

  return (
    <Wrapper width={['236px', '236px', '236px', '320px']} pr="s" bg="white">
      <AddNewContent />

      <ServicesCards />

      <Text
        fontSize={['h3', 'h3', 'h3', 'h2']}
        lineHeight={['h3', 'h3', 'h3', 'h2']}
        fontWeight="bold"
        mt="m"
        mb="xs"
        color="grey_600"
      >
        {translations[languageCode]['publishing history']}
      </Text>
      <PublishingHistory />
      <Text
        fontSize={['h3', 'h3', 'h3', 'h2']}
        lineHeight={['h3', 'h3', 'h3', 'h2']}
        fontWeight="bold"
        mt="m"
        mb="xs"
        color="grey_600"
      >
        {translations[languageCode]['top selling titles']}
      </Text>
      <Text
        fontSize={['h6', 'h6', 'h6', 'h5']}
        lineHeight={['h6', 'h6', 'h6', 'h6']}
        fontWeight="bold"
        mb="xs"
        color="grey_200"
      >
        {translations[languageCode]['last 30 days']}
      </Text>
      <TopSelling />
    </Wrapper>
  );
}

export default Sidebar;
