import React from 'react';
import styled from 'styled-components';
import { space, layout } from 'styled-system';

const Wrapper = styled('svg').withConfig({
  shouldForwardProp: (prop) => !['width'].includes(prop),
})`
  width: 16px;

  ${layout}
  ${space}
`;

function Book({ style }) {
  return (
    <Wrapper {...style} viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M15.3 0.490234H3.60002C2.51462 0.490234 0.900024 1.20933 0.900024 3.19023V15.7902C0.900024 17.7711 2.51462 18.4902 3.60002 18.4902H17.1V16.6902H3.61082C3.19502 16.6794 2.70002 16.5156 2.70002 15.7902C2.70002 15.0648 3.19502 14.901 3.61082 14.8902H17.1V2.29023C17.1 1.29753 16.2927 0.490234 15.3 0.490234ZM15.3 13.0902H2.70002V3.19023C2.70002 2.46483 3.19502 2.30103 3.60002 2.29023H15.3V13.0902Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18" fill="white" transform="translate(0 0.490234)" />
        </clipPath>
      </defs>
    </Wrapper>
  );
}

export default Book;
