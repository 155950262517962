/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getHistoryRequest } from 'redux-api/action/history';
import selectPublishingHistory from 'redux-api/reselect/history';
import selectProfile from 'redux-api/reselect/profile';
import PublishCard from 'theme/publish-card';
import Text from 'theme/text';
import translations from 'translations';
import CardPlaceholder from 'theme/card-placeholder';
import Cards from 'theme/content-loader/cards';
import BtnLink from 'theme/link/external-link';
import getPlatformUrls from '../../lib/get-platform-urls';

const Wrapper = styled.div``;

const WrpLink = styled.div`
  width: fit-content;
`;

function PublishingHistory() {
  const dispatch = useDispatch();

  const { languageCode } = useSelector((state) => state.config);
  const { isWaiting, error } = useSelector((state) => state.history);
  const publishingHistory = useSelector(selectPublishingHistory);
  const { payload } = useSelector(selectProfile);
  let platformUrls = {};
  if (payload.abilities) platformUrls = getPlatformUrls(payload.abilities);

  useEffect(() => {
    dispatch(getHistoryRequest());
  }, [dispatch]);

  return (
    <Wrapper>
      {isWaiting ? (
        <Cards />
      ) : publishingHistory.length === 0 ? (
        <CardPlaceholder />
      ) : (
        publishingHistory.map((item) => <PublishCard key={item.id} payload={item} error={error} />)
      )}
      <WrpLink>
        <BtnLink
          style={{ p: 0 }}
          title={translations[languageCode]['view catalog']}
          href={platformUrls.publish}
          target="_blank"
          rel="noreferrer"
          label={
            <Text
              fontSize={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
              lineHeight={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
              fontWeight="bold"
            >
              {translations[languageCode]['view catalog']}
            </Text>
          }
        />
      </WrpLink>
    </Wrapper>
  );
}

export default PublishingHistory;
