import React from 'react';

function InfoCircle({ width = '19' }) {
  return (
    <svg width={width} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00586 5.61328C8.53987 5.61328 8.16211 5.99104 8.16211 6.45703C8.16211 6.92302 8.53987 7.30078 9.00586 7.30078C9.47185 7.30078 9.84961 6.92302 9.84961 6.45703C9.84961 5.99104 9.47185 5.61328 9.00586 5.61328Z"
        fill="currentColor"
      />
      <path
        d="M8.25586 12.9258C8.25586 13.34 8.59165 13.6758 9.00586 13.6758C9.42007 13.6758 9.75586 13.34 9.75586 12.9258L9.75586 8.98828C9.75586 8.57407 9.42007 8.23828 9.00586 8.23828C8.59164 8.23828 8.25586 8.57407 8.25586 8.98828L8.25586 12.9258Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5059 9.55078C16.5059 13.6929 13.148 17.0508 9.00586 17.0508C4.86372 17.0508 1.50586 13.6929 1.50586 9.55078C1.50586 5.40865 4.86372 2.05078 9.00586 2.05078C13.148 2.05078 16.5059 5.40865 16.5059 9.55078ZM9.00586 15.5508C12.3196 15.5508 15.0059 12.8645 15.0059 9.55078C15.0059 6.23707 12.3196 3.55078 9.00586 3.55078C5.69215 3.55078 3.00586 6.23707 3.00586 9.55078C3.00586 12.8645 5.69215 15.5508 9.00586 15.5508Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default InfoCircle;
