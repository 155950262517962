import React from 'react';
import styled from 'styled-components';
import { flexbox, space } from 'styled-system';
import Card from 'theme/card';
import Text from 'theme/text';
import Chart from './chart';
import Row from './row';

const Wrapper = styled.div`
  ${space}

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  & > .box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;

const List = styled.div`
  ${space}
  ${flexbox}

  display: flex;
  flex-direction: column;
  flex: 1;

  & > li:last-child {
    border-bottom: 0px;
  }
`;

function AssetRevenue({ title, id, payload }) {
  return (
    <Wrapper>
      <Text
        mb="m"
        fontSize={['pSmall', 'pSmall', 'pSmall', 'h2']}
        lineHeight={['pSmall', 'pSmall', 'pSmall', 'h2']}
        fontWeight="bold"
        color="grey_600"
        textAlign="left"
      >
        {title}
      </Text>

      <div className="box">
        <Chart id={id} maxWidth={200} />

        <Card
          ml={['s', 'xxl', 'xxl', 'xxl']}
          maxWidth="208px"
          width="100%"
          bg="white"
          border="none"
        >
          <List>
            {payload.pieChartData.map((t) => (
              <Row key={t.label} payload={t} />
            ))}
          </List>
        </Card>
      </div>
    </Wrapper>
  );
}

export default AssetRevenue;
