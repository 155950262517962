import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import Text from 'theme/text';
import Write from 'theme/icons/write';
import Distribute from 'theme/icons/distribute';
import Print from 'theme/icons/print';
import LinkBorder from 'theme/link/border';
import translations from 'translations';
import { useSelector } from 'react-redux';
import selectProfile, { selectServices } from 'redux-api/reselect/profile';
import getPlatformUrls from '../../lib/get-platform-urls';

const Wrapper = styled.div`
  width: 100%;

  ${space}

  & > .divider {
    width: 100%;
    height: 1px;
    background-color: ${themeGet('colors.primary_100')};
  }
`;

const Section = styled.div`
  width: 100%;

  ${space}

  & > .row {
    display: flex;
    flex-direction: row;
  }

  & > .row > .circle-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: ${({ bgColor }) => themeGet(`colors.${bgColor}`)};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > .row > .content {
    display: flex;
    flex: 1;
    padding: 0 ${themeGet('space.m')}px;
  }
`;

function StepNewContent() {
  const { languageCode } = useSelector((state) => state.config);
  const { payload } = useSelector(selectProfile);
  const { services } = useSelector(selectServices);

  const hubService = services.find((service) => service?.platform?.name === 'DISTRIBUTION');
  const writeService = services.find((service) => service?.platform?.name === 'WRITE');

  let platformUrls = {};
  if (payload.abilities) platformUrls = getPlatformUrls(payload.abilities);

  return (
    <Wrapper px="m" mb="m">
      <div className="divider" />

      <Text fontSize="h2" lineHeight="h2" fontWeight="bold" color="grey_600" my="l">
        {translations[languageCode]['Is your file ready for distribution?']}
      </Text>

      <Section bgColor="distribute" pb="m">
        <div className="row">
          <div className="circle-icon">
            <Distribute width="30" color="white" />
          </div>

          <div className="content">
            <Text fontSize="h3" lineHeight="h3" fontWeight="normal" color="grey_400">
              {
                translations[languageCode][
                  'Go to StreetLib Hub and start publishing your ebooks, audiobooks, and print-on-demand books, with all the major online libraries!'
                ]
              }
            </Text>
          </div>

          <LinkBorder
            href={
              !hubService && hubService?.isNotSignable
                ? '/onboarding'
                : hubService?.enabled
                ? platformUrls.publish
                : `/enable-service?id=SHUFFLE_PUBLISH`
            }
            type="button"
            style={{
              maxWidth: '220px',
              height: '56px',
              px: 's',
              borderColor: 'distribute',
              justifyContent: 'center',
            }}
          >
            <Text fontSize="pLarge" lineHeight="pLarge" fontWeight="medium">
              {translations[languageCode]['Upload Content']}
            </Text>
          </LinkBorder>
        </div>
      </Section>

      <div className="divider" />

      <Text fontSize="h2" lineHeight="h2" fontWeight="bold" color="grey_600" my="l">
        {translations[languageCode]['Do you need assistance with the files to be distributed?']}
      </Text>

      <Section bgColor="write" pb="m">
        <div className="row">
          <div className="circle-icon">
            <Write width="30" color="white" />
          </div>

          <div className="content">
            <Text fontSize="h3" lineHeight="h3" fontWeight="normal" color="grey_400">
              {
                translations[languageCode][
                  'Go to streetLib Write and create your own print-ready ePubs and Mobi, PDFs files and covers. With a few, simple steps you can get your files ready for distribution!'
                ]
              }
            </Text>
          </div>

          <LinkBorder
            href={
              !writeService && writeService?.enabled
                ? platformUrls.write
                : `/enable-service?id=SHUFFLE_WRITE`
            }
            type="button"
            style={{
              maxWidth: '220px',
              height: '56px',
              px: 's',
              borderColor: 'write',
              justifyContent: 'center',
            }}
          >
            <Text fontSize="pLarge" lineHeight="pLarge" fontWeight="medium">
              {translations[languageCode]['Create your own book']}
            </Text>
          </LinkBorder>
        </div>
      </Section>

      <Section bgColor="print" py="m">
        <div className="row">
          <div className="circle-icon">
            <Print width="30" color="white" />
          </div>

          <div className="content">
            <Text fontSize="h3" lineHeight="h3" fontWeight="normal" color="grey_400">
              {
                translations[languageCode][
                  'You can rely on the StreetLib Ready Team for the production of your ebook and audiobook files, publication of your PDFs, and to access many other useful services!'
                ]
              }
            </Text>
          </div>

          <LinkBorder
            href="https://readybooks.io"
            type="button"
            style={{
              maxWidth: '220px',
              height: '56px',
              px: 's',
              borderColor: 'print',
              justifyContent: 'center',
            }}
          >
            <Text fontSize="pLarge" lineHeight="pLarge" fontWeight="medium">
              {translations[languageCode]['Find out editorial services']}
            </Text>
          </LinkBorder>
        </div>
      </Section>
    </Wrapper>
  );
}

export default StepNewContent;
