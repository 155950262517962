/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import base64 from 'base-64';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { resetFilters } from 'redux-api/action/filters';
import { getAggrRequest } from 'redux-api/action/aggr';
import selectTopSelling from 'redux-api/reselect/top-selling';
import Text from 'theme/text';
import BtnLink from 'theme/link';
import TopSellingCard from 'theme/top-selling-card';
import CardPlaceholder from 'theme/card-placeholder';
import Cards from 'theme/content-loader/cards';
import translations from 'translations';

const Wrapper = styled.div``;

function TopSelling() {
  const dispatch = useDispatch();
  const { push } = useRouter();

  const [queriedKey, setQueriedKey] = useState(null);
  const { languageCode } = useSelector((state) => state.config);
  const { formatDate, currency } = useSelector((state) => state.config);
  const topSelling = useSelector((state) => selectTopSelling(state, queriedKey));
  const { error } = useSelector((state) => state.aggr);
  const { analytics_type } = useSelector((state) => state.profile.payload);

  const dateTo = useMemo(() => {
    const today = moment();

    return today.format(formatDate);
  }, [formatDate]);

  const dateFrom = useMemo(() => {
    const today = moment();

    return today.subtract(30, 'days').format(formatDate);
  }, [formatDate]);

  const query = useMemo(
    () =>
      // return `currency=${currency}&term=title&size=3&dateTo=2021-05-01&dateFrom=2021-01-01&include=cover_link,title,isbn&dataType=real_data`;
      `currency=${currency}&term=title&size=3&dateTo=${dateTo}&dateFrom=${dateFrom}&include=cover_link,title,isbn&version=7&enterprise=${
        analytics_type || 'standard'
      }`,
    [dateTo, dateFrom, currency, analytics_type],
  );

  useEffect(() => {
    if (!query) return;

    const key = base64.encode(query);

    setQueriedKey(key);

    dispatch(
      getAggrRequest({
        term: 'title',
        key,
        query,
        currency,
      }),
    );
  }, [dispatch, dateTo, dateFrom, query, currency]);

  const handleClickCard = useCallback(
    (isbn) => {
      if (isbn) {
        dispatch(resetFilters());

        const parsedQuery = isbn ? { filters: `isbn=${isbn}` } : null;
        push({ pathname: '/analytics', query: parsedQuery });
      }
    },
    [dispatch, push],
  );

  return (
    <Wrapper>
      {topSelling.isWaiting ? (
        <Cards />
      ) : topSelling.payload.length === 0 ? (
        <CardPlaceholder />
      ) : (
        topSelling.payload.map((el) => (
          <TopSellingCard key={el.id} payload={el} error={error} onClick={handleClickCard} />
        ))
      )}

      <BtnLink
        style={{ p: 0 }}
        href="/analytics"
        title={translations[languageCode]['go to analytics']}
      >
        <Text
          fontSize={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
          lineHeight={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
          fontWeight="bold"
        >
          {translations[languageCode]['go to analytics']}
        </Text>
      </BtnLink>
    </Wrapper>
  );
}

export default TopSelling;
