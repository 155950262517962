import React from 'react';
import styled from 'styled-components';
import { space, layout } from 'styled-system';

const Wrapper = styled('svg').withConfig({
  shouldForwardProp: (prop) => !['width'].includes(prop),
})`
  width: 19px;

  ${layout}
  ${space}
`;

function Chart({ style }) {
  return (
    <Wrapper {...style} viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M10.8303 3.49023H12.8303V14.4902H10.8303V3.49023ZM14.8303 0.490234H16.8303V14.4902H14.8303V0.490234ZM6.83026 6.49023H8.83026V14.4902H6.83026V6.49023ZM1.83026 16.4902H17.8303V18.4902H1.83026V16.4902ZM2.83026 9.49023H4.83026V14.4902H2.83026V9.49023Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18" fill="white" transform="translate(0.830261 0.490234)" />
        </clipPath>
      </defs>
    </Wrapper>
  );
}

export default Chart;
