import _ from 'lodash';

// Return platform urls from abilities
const getPlatformUrls = (abilities) => {
  const platformWrite = _.find(abilities, { platform: { name: 'WRITE' } });
  const platformPublish = _.find(abilities, { platform: { name: 'PUBLISH' } });
  const platformDistribution = _.find(abilities, { platform: { name: 'DISTRIBUTION' } });
  const platformPrint = _.find(abilities, { platform: { name: 'POS' } });
  const shufflePublish = _.find(abilities, { platform: { name: 'SHUFFLE_PUBLISH' } });
  const shufflePrint = _.find(abilities, { platform: { name: 'SHUFFLE_POS' } });

  const showWrite = (platformWrite && platformWrite.enabled && platformWrite.visible) || false;
  const showPublish = (platformPublish && platformPublish.enabled) || false;
  const showDistribution =
    (platformDistribution && platformDistribution.enabled && platformDistribution.visible) || false;
  const showPrint = (platformPrint && platformPrint.enabled) || false;
  // const showShufflePublish = shufflePublish && shufflePublish.enabled && shufflePublish.visible || false;
  // const showShufflePrint = shufflePrint && shufflePrint.enabled && shufflePrint.visible || false;

  const platformUrls = {
    write: null,
    publish: shufflePublish && shufflePublish.platform ? shufflePublish.platform.url : null,
    print: shufflePrint && shufflePrint.platform ? shufflePrint.platform.url : null,
  };

  if (showWrite) platformUrls.write = platformWrite.platform.url;

  if (showPublish && !showDistribution) platformUrls.publish = platformPublish.platform.url;
  else if (showPublish && showDistribution)
    platformUrls.publish = platformDistribution.platform.url;
  else if (showDistribution) platformUrls.publish = platformDistribution.platform.url;

  if (showPrint && !showDistribution) platformUrls.print = platformPrint.platform.url;
  else if (showPrint && showDistribution) platformUrls.print = platformDistribution.platform.url;

  return platformUrls;
};

export default getPlatformUrls;
