import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import base64 from 'base-64';
import { useSelector, useDispatch } from 'react-redux';
import { getCountRequest } from 'redux-api/action/count';
import TemporaryUnits from './temporary-units';
import TemporaryRevenue from './temporary-revenue';
import LastInvoice from './last-invoice';

function CounterCards() {
  const [queriedKey, setQueriedKey] = useState(null);

  const dispatch = useDispatch();

  const { formatDate, currency } = useSelector((state) => state.config);
  const { analytics_type } = useSelector((state) => state.profile.payload);

  const dateTo = useMemo(() => {
    const today = moment();

    return today.format(formatDate);
  }, [formatDate]);

  const dateFrom = useMemo(() => {
    const today = moment();

    return today.subtract(30, 'days').format(formatDate);
  }, [formatDate]);

  const query = useMemo(() => {
    if (!dateTo || !dateFrom) return null;

    return `currency=${currency}&dataType=temporary_data&dateFrom=${dateFrom}&dateTo=${dateTo}&version=7&enterprise=${
      analytics_type || 'standard'
    }`;
  }, [dateTo, dateFrom, currency, analytics_type]);

  useEffect(() => {
    if (!query) return;

    const key = base64.encode(query);
    setQueriedKey(key);

    dispatch(
      getCountRequest({
        key,
        query,
        currency,
      }),
    );
  }, [dispatch, query, currency]);

  return (
    <>
      <TemporaryRevenue queriedKey={queriedKey} />
      <TemporaryUnits queriedKey={queriedKey} />
      <LastInvoice />
    </>
  );
}

export default CounterCards;
