/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import base64 from 'base-64';
import { useDispatch, useSelector } from 'react-redux';
import { getAggrRequest } from 'redux-api/action/aggr';
import selectTopAssets from 'redux-api/reselect/top-assets';
import Card from 'theme/assets-revenue';
import translations from 'translations';

function AssetsRevenue({ dateTo, dateFrom, dataType, filters }) {
  const dispatch = useDispatch();

  const [queriedKey, setQueriedKey] = useState(null);

  const pieAssets = useSelector((state) => selectTopAssets(state, queriedKey));
  const { currency, languageCode } = useSelector((state) => state.config);
  const { analytics_type } = useSelector((state) => state.profile.payload);

  const query = useMemo(() => {
    if (!dataType || !dateTo || !dateFrom) return null;

    let queryString = `currency=${currency}&dataType=${dataType}&term=item_type&size=10&dateTo=${dateTo}&dateFrom=${dateFrom}&version=7&enterprise=${
      analytics_type || 'standard'
    }`;

    if (filters) {
      queryString = `${queryString}&filters=${filters}`;
    }

    return queryString;
  }, [dateTo, dateFrom, dataType, filters, currency, analytics_type]);

  useEffect(() => {
    if (!query) return;

    const key = base64.encode(query);

    setQueriedKey(key);

    dispatch(
      getAggrRequest({
        term: 'item_type',
        key,
        query,
        currency,
      }),
    );
  }, [dispatch, dateTo, dateFrom, dataType, query, currency]);

  return (
    <Card
      title={translations[languageCode]['revenue by assets']}
      payload={pieAssets}
      id={queriedKey}
    />
  );
}

export default AssetsRevenue;
