/* eslint-disable camelcase */
import { createSelector } from 'reselect';
import formatCurrency from 'lib/format-currency';
import changeCoverUrl from 'lib/change-cover-url';

const selectTopSelling = createSelector(
  (state) => state.aggr.payload,
  (_, key) => key,
  (payload, key) => {
    const result = { isWaiting: true, payload: [] };

    if (!payload[key] || !payload[key].data) return result;

    const {
      currency,
      isWaiting,
      data: {
        aggregations: {
          results: { buckets },
        },
      },
    } = payload[key];

    result.payload = buckets.map((bucket) => {
      const { key: id, include, current_period, previous_period } = bucket;

      const cover_link =
        include.hits && include.hits.hits && include.hits.hits[0]
          ? include.hits.hits[0]._source.cover_link
          : null;

      const title =
        include.hits && include.hits.hits && include.hits.hits[0]
          ? include.hits.hits[0]._source.title
          : null;

      const isbn =
        include.hits && include.hits.hits && include.hits.hits[0]
          ? include.hits.hits[0]._source.isbn
          : null;

      const totalCurrentRevenues = formatCurrency(currency, current_period.total_revenues.value);
      const totalPreviousRevenues = formatCurrency(currency, previous_period.total_revenues.value);

      let percentEarning = 0;
      if (previous_period.total_revenues.value !== 0) {
        percentEarning = Math.round(
          ((current_period.total_revenues.value - previous_period.total_revenues.value) /
            previous_period.total_revenues.value) *
            100,
        );
      }

      return {
        id,
        isbn,
        title,
        totalCurrentRevenues,
        totalPreviousRevenues,
        percentEarning,
        cover: changeCoverUrl(cover_link),
      };
    });

    return { ...result, isWaiting };
  },
);

export default selectTopSelling;
