import React from 'react';
import Link from 'next/link';
import { layout, typography, space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

const StyledA = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  min-width: 140px;
  color: ${themeGet('colors.primary_400')};
  padding: 0 ${themeGet('space.s')}px;
  border-radius: 8px;

  &:hover {
    color: ${themeGet('colors.primary_500')};
  }

  &:focus {
    color: ${themeGet('colors.primary_600')};
  }

  &:disabled {
    opacity: 0.5;
  }

  ${layout}
  ${space}
  ${typography}
`;

function CustomLink({ title, children, type, href, disabled, style }) {
  return (
    <Link title={title} href={href}>
      <StyledA
        fontSize="h4"
        lineHeight="h4"
        fontWeight="bold"
        type={type}
        disabled={disabled}
        {...style}
      >
        {children}
      </StyledA>
    </Link>
  );
}

export default CustomLink;
