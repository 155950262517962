import React from 'react';
import styled from 'styled-components';
import { color, space } from 'styled-system';
import Text from 'theme/text';

const Wrapper = styled.div`
  ${space}

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > div:last-child {
    margin-right: 0;
  }
`;

const Tag = styled.a`
  ${color}
  ${space}

  width: fit-content;
  border-radius: 4px;
  text-decoration: none;
`;

function Tags({ payload }) {
  return (
    <Wrapper mt="m">
      {payload.map((tag) => (
        <Tag
          title={tag}
          href={tag.url}
          target="_blank"
          rel="noreferrer"
          key={tag.id}
          bg="primary_100"
          px="xs"
          py="xxxs"
          mr="xs"
          mb="xs"
        >
          <Text
            fontSize={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
            lineHeight={['pSmall', 'pSmall', 'pSmall', 'pLarge']}
            fontWeight="normal"
            color="grey_600"
          >
            {tag.name}
          </Text>
        </Tag>
      ))}
    </Wrapper>
  );
}

export default Tags;
